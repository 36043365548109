import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios';
import { MdOutlineSubtitles } from "react-icons/md";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faFilm } from '@fortawesome/free-solid-svg-icons'
import styles from './ExportData.module.css'
import LoadingStatus from '../LoadingStatus/LoadingStatus'
import AppDataContext from '../../contexts/AppDataContext';

function ExportData() {
	const exportPostVideoUrl = '/video/video_burn/'
	const exportGetVideoUrl = '/video/video_burn'
	const downloadSrtUrl = '/transcript/dl_srt'
	const [exportLink, setExportLink] = useState({'condition': false, 'url': ''})
	const [isPreparing, setIsPreparing] = useState(false)
	const [mp4ButtonStyle, setMp4ButtonStyle] = useState(styles.export + ' ' + styles.mp4Button)
	const { uploadParams, baseURL } = useContext(AppDataContext)

	let intervalId
	useEffect(() => {
		if (exportLink.condition) {
			clearInterval(intervalId)
		}// eslint-disable-next-line
	},[intervalId])

	const sendGetRequest = () => {
		axios.get(`${baseURL}${exportGetVideoUrl}?file_id=${uploadParams.file_id}`,
							{withCredentials: true})
				.then((res) => {
					setExportLink({'condition': true, 'url': res.data.url})
					setIsPreparing(false)
					setMp4ButtonStyle(mp4ButtonStyle + ' ' + styles.greenBackground)
				})
				.catch((err) => console.log(err.response.data.message))
	}

	const handleExportMp4 = () => {
		if (exportLink.condition) {
			const link = document.createElement('a');
			link.href = exportLink.url;
			link.setAttribute(
				'download',
				'video.mp4',
			);
			document.body.appendChild(link);
			link.click();
			link.parentNode.removeChild(link)
		} else {
			axios.post(`${baseURL}${exportPostVideoUrl}`,
								 {'file_id': uploadParams.file_id},
								 {withCredentials: true})
					.then(() => {setExportLink({'condition': false, 'url': ''})
													setIsPreparing(true)
													setMp4ButtonStyle(styles.export + ' ' + styles.mp4Button)})
					.catch((err) => console.log(err))
			intervalId = setInterval(sendGetRequest, 5000)
		}
	}

	const handleExportSrt = () => {
		axios.get(`${baseURL}${downloadSrtUrl}?file_id=${uploadParams.file_id}`,
							{withCredentials: true})
				.then((res) => {
					const link = document.createElement('a');
					link.target = '_blank'
					link.href = res.data.url;
					link.setAttribute(
						'download',
						'VideoSub.srt',
					);
					document.body.appendChild(link);
					link.click();
					link.parentNode.removeChild(link)})
				.catch((err) => {console.log(err)})}

  return (
		<div className={'row justify-content-between ' + styles.mainContainer}>
			<div className='col-7'>
				<div className={(!exportLink.condition && styles.loadingStatusHide)}>
					<p className={styles.readyText}>Your Video is Ready, Click Again</p>
				</div>
				<div className={(!isPreparing && styles.loadingStatusHide)}>
					<LoadingStatus loadingMessage={'Preparing your download ...'} />
				</div>
			</div>
			<div className={'col-3 ' + styles.exportColumn}>
				<div className={styles.exportContainer}>
					<div className={'row ' + styles.rowContain}>
						<div className={styles.export + ' ' + styles.exportHeader}>
							<FontAwesomeIcon icon={faDownload} />&#160;&#160;Export
						</div>
					</div>
					<div className={'row ' + styles.rowContain}>
						<button className={mp4ButtonStyle}
										onClick={handleExportMp4}>
							<FontAwesomeIcon icon={faFilm} />&#160;&#160;&#160;&#160;&#160;&#160;MP4
						</button>
					</div>
					<div className={'row ' + styles.rowContain}>
						<button className={styles.export + ' ' + styles.srtButton}
								onClick={() => handleExportSrt()}>
							<MdOutlineSubtitles />&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;SRT
						</button>
					</div>
				</div>
			</div>
		</div>
  )
}

export default ExportData
