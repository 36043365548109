import { useEffect, useContext } from "react"
import { Link } from "react-router-dom"
import styles from './DisplayVideo.module.css'
import AppDataContext from "../../contexts/AppDataContext"

function DisplayVideo() {
  const {videoFileURL, subtitleSrc,
         handleSubtitleTrack, Subtitle,
         isAuth} = useContext(AppDataContext)

  useEffect(() => {
    handleSubtitleTrack()// eslint-disable-next-line
  }, [Subtitle])
  
  return (
    <div>
        {(isAuth && videoFileURL) && <video src={videoFileURL}
                                      className={styles.videoFile}
                                      controls preload="metadata">
          <track label="English" kind="subtitles" srcLang="en" src={subtitleSrc} default />
          </video>}
        {!isAuth && <div className={styles.loginLink}>
          <Link to='/login'>Want to Upload a video? Login first</Link></div>}
    </div>
  )
}

export default DisplayVideo
