import { useContext, useState } from 'react'
import styles from './SubtitleList.module.css'
import SubtitleRow from '../SubtitleRow'
import BoldItem from '../BoldItem/BoldItem'
import AppDataContext from '../../contexts/AppDataContext'

function SubtitleList() {
  const { Subtitle, setPanelItemValue,
          retreiveIndex,retreiveLength } = useContext(AppDataContext)
  const [activeItemId, setActiveItemId] = useState('')
  const handleFocus = (item) => {
    setActiveItemId(item.id);
    setPanelItemValue(item);
  }
  return (
    <div className={styles.subtitleListDiv}>
      <ul className={styles.ul}>
        {Subtitle && Subtitle.map((item) => (
          ((item && item.id === activeItemId) ?
            <li key={`${item.id}`}><BoldItem item={item}
                      isFirst={retreiveIndex(item) === 0} 
                      isLast={retreiveIndex(item) === (retreiveLength() - 1)}/></li> :
            <li key={`${item.id}`}
                className={styles.subtitleRow}
                onFocus={() => handleFocus(item)}>
              <SubtitleRow>{item}</SubtitleRow>
            </li>)
          )
        )}
      </ul>
    </div>
  )
}

export default SubtitleList

