import { useContext } from 'react';
import styles from './VideoTimeline.module.css'
import AppDataContext from '../../contexts/AppDataContext';

function VideoTimeline() {
  const {maxAmount} = useContext(AppDataContext)
  return (
    <div className={styles.baseContainer}>
      <div className={styles.timelineContainer}>
        <input type='range' name='timeLine' id='timeLine'
              className={styles.input}
              min={0.000}
              max={maxAmount}
              defaultValue={0.000}
              step={0.001} />
        <label htmlFor='timeLine'>Video Timeline</label>
      </div>
    </div>
  )
}

export default VideoTimeline