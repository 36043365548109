import React from 'react'
import PropTypes from 'prop-types'
import ReactLoading from 'react-loading'
import styles from './LoadingStatus.module.css'

function LoadingStatus({loadingMessage}) {
  return (
    <div className={styles.statusContainer}>
      <div className={styles.status}>
        <ReactLoading type="bars" color="#BCF2F6"
                  height={50} width={50} />
      </div>
      <div className={styles.messageContainer}>
        <h5 className={styles.message}>{loadingMessage}</h5>
      </div>
    </div>
  )
}

LoadingStatus.propTypes = {
  loadingMessage: PropTypes.string
}

export default LoadingStatus
