import React, { useContext, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faScissors, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'
import styles from './EditPanel.module.css'
import AppDataContext from '../../contexts/AppDataContext'

function EditPanel() {
  const { handleInsertion, handleSplition, handleUpdate,
					handleDeletion, handleSubtitleChange,
					isFormatted, isValidTime, panelItem } = useContext(AppDataContext)
	const [item, setItem] = useState(panelItem)
	const [isNegative, setIsNegative] = useState({'start_time': false, 'end_time': false})
	const [checkItem, setCheckItem] = useState(panelItem)
	const [validationMessage, setValidationMessage] = useState('')
	
	useEffect(() => {
		setItem(panelItem)
		setCheckItem(panelItem)
	}, [panelItem])

	const fullUpdate = (property, eventValue) => {
		setCheckItem({...checkItem, [property]: eventValue})
		handleSubtitleChange({...item, [property]: eventValue})
		handleUpdate(panelItem.id, property, eventValue)
	}

	const handleChange = (event, property) => {
		setItem({...item, [property]: event.target.value})
	}

	const handleTimeUpdate = (event, property) => {
		let eventValue = event.target.value
		if (isFormatted(eventValue) &&
				isValidTime(eventValue, property, panelItem.id)) {
			setValidationMessage('')
			fullUpdate(property, eventValue)
		} else {
			setValidationMessage('Enter a valid time with the existing standard format')
			setItem(checkItem)
		}
	}

	const handleDecrement = (timeProperty) => {
		let firstPart = item[timeProperty].substr(0, 6)
		let secondPart = Number(item[timeProperty].substr(6, 6)) - 0.5
		if (secondPart < 0) {
			setIsNegative[timeProperty] = true
		} else {
			secondPart = secondPart.toFixed(3).padStart(6, '0')
			let decrementedTime = firstPart + secondPart
			setItem({...item, [timeProperty]: decrementedTime})
			fullUpdate(timeProperty, decrementedTime)
		}
	}

	const handleIncrement = (timeProperty) => {
		let firstPart = item[timeProperty].substr(0, 6)
		let secondPart = Number(item[timeProperty].substr(6, 6)) + 0.5
		secondPart = secondPart.toFixed(3).padStart(6, '0')
		let incrementedTime = firstPart + secondPart

		setItem({...item, [timeProperty]: incrementedTime})
		fullUpdate(timeProperty, incrementedTime)
	}

  return (
    <div className={'container-fluid ' + styles.editArea}>
			<div className='row justify-content-center'>
				<div className='col-xl-6 col-lg-6 col-md-7 col-sm-12 col-12'>
					<div className='row justify-content-left'>
						<div className='col-xl-9 col-lg-9 col-md-11 col-sm-12 col-12'>
							<div className={'row ' + styles.editContainers}>
								<div className={'col-4 ' + styles.buttonContainer}>
									<button className={styles.editButton}
													disabled={item.id === ''}
													onClick={() => handleInsertion(item)}>
										<FontAwesomeIcon icon={faPlus} />&#160;Insert
									</button>
								</div>
								<div className={'col-4 ' + styles.buttonContainer}>
									<button className={styles.editButton + ' '
																	+	styles.blackBackground}
													disabled={item.id === ''}
													onClick={() => handleSplition(item)}>
										<FontAwesomeIcon icon={faScissors} />&#160;Split
									</button>
								</div>
								<div className={'col-4 ' + styles.buttonContainer}>
									<button className={styles.editButton}
													disabled={item.id === ''}
													onClick={() => handleDeletion(item)}>
										<FontAwesomeIcon icon={faTrash} />&#160;Delete
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className={'col-xl-6 col-lg-6 col-md-5 col-sm-12 col-12 ' + styles.inputColumn}>
					<div className={styles.inputContainer}>
						<div className={'row ' + styles.inputRow}>
							<div className={'col-6 ' + styles.inputColumn}>
								<div className={styles.inputWrapper}>
									<div className={styles.inputButtonContainer}>
										<button className={styles.inputButton
															+ ' ' + styles.inputButtonLeft}
														disabled={(item.id === '') || isNegative.start_time}
														onClick={() => handleDecrement('start_time')}>
											<FontAwesomeIcon icon={faMinus} />
										</button>
									</div>
									<div className={styles.timeInputContainer}>
										<input className={styles.timeInput}
														type='text'
														name='start_time' 
														id='startTime'
														value={item.start_time}
														disabled={panelItem.start_time === ''}
														placeholder='00:00:00.000'
														onChange={(event) => handleChange(event, 'start_time')}
														onBlur={(event) => {handleTimeUpdate(event, 'start_time')}} />
									</div>
									<div className={styles.inputButtonContainer}>
										<button className={styles.inputButton}
														disabled={item.id === ''}
														onClick={() => handleIncrement('start_time')}>
											<FontAwesomeIcon icon={faPlus} />
										</button>
									</div>
								</div>
							</div>
							<div className={'col-6 ' + styles.inputColumn}>
								<div className={styles.inputWrapper}>
									<div className={styles.inputButtonContainer}>
										<button className={styles.inputButton
															+ ' ' + styles.inputButtonLeft
															+ ' ' + styles.blackTimeInput}
														disabled={(item.id === '') || isNegative.end_time}
														onClick={() => handleDecrement('end_time')}>
											<FontAwesomeIcon icon={faMinus} />
										</button>
									</div>
									<div className={styles.timeInputContainer}>
										<input className={styles.timeInput 
													+ ' ' + styles.blackTimeInput}
													type='text' 
													name='end_time'
													id='EndTime'
													value={item.end_time}
													disabled={panelItem.start_time === ''}
													placeholder='00:00:00.000'
													onChange={(event) => handleChange(event, 'end_time')}
													onBlur={(event) => {handleTimeUpdate(event, 'end_time')}} />
									</div>
									<div className={styles.inputButtonContainer}>
										<button className={styles.inputButton
															+ ' ' + styles.blackTimeInput}
														disabled={item.id === ''}
														onClick={() => handleIncrement('end_time')}>
											<FontAwesomeIcon icon={faPlus} />
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{validationMessage && <div className='alert alert-warning'>{validationMessage}</div>}
    </div>
  )
}


export default EditPanel
