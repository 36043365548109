import React, { useContext, useEffect, useRef, useState } from 'react'
import Cookies from 'js-cookie';
import axios from 'axios';
import styles from './Dashboard.module.css'
import { useNavigate } from 'react-router-dom';
import { MdWindow, MdMovie, MdSettings } from "react-icons/md";
import { LuLogOut } from "react-icons/lu";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { HiCurrencyDollar } from "react-icons/hi2";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCrown } from '@fortawesome/free-solid-svg-icons';
import AppDataContect from '../../../contexts/AppDataContext';
import logoImage from '../../../data/images/FilmEditor-fav-full-white.png'

function Dashboard() {
  const videoRef = useRef(null)
  const [activation, setActivation] = useState(
              {'dashboard': true, 'projects': false, 'upgrade': false,
                'credit': false, 'settings': false})
  const [videoThumbnails, setVideoThumbnails] = useState([])
  const navigate = useNavigate()
  const { baseURL } = useContext(AppDataContect)
  const dashboardEndpoint = '/video/dashboard/'

  useEffect(() => {
    axios.get(`${baseURL}${dashboardEndpoint}`,
              {withCredentials: true})
        .then((res) => setVideoThumbnails(res.data.videos))
        .catch((err) => console.log(err)) // eslint-disable-next-line
  },[])

  const handleActivation = (section) => {
    let activeObject = {...activation}
    for (let key in activation){
      if (key === section) {
        activeObject[key] = true
      } else {
        activeObject[key] = false
      }
    }
    setActivation(activeObject)
  }

  const logout = () => {
    Cookies.remove('csrftoken')
    navigate('/login')
  }

  const scroll = (offset) => {
    videoRef.current.scrollLeft += offset
  }

  return (
    <div className={styles.mainContainer}>
      <div className={'row ' + styles.mainRow}>
        <div className={'col-xl-2 col-lg-3 col-md-4 col-sm-5 col-6 ' 
                      + styles.mainColumn}>
          <div className={styles.menuColumn}>
            <div className={styles.logoContainer}
                onClick={() => navigate('/')}>
              <img src={logoImage} alt="LOGO" className={styles.logo} />
            </div>
            <ul className={styles.menuList}>
              <li className={activation.dashboard ? 
                  styles.menuItem + ' ' + styles.activeItem : styles.menuItem}
                  onClick={() => handleActivation('dashboard')}>
                <div className={styles.itemText}>
                  <MdWindow className={styles.iconsColor} />&#160;&#160;Dashboard
                </div>
              </li>
              <li className={activation.projects ? 
                  styles.menuItem + ' ' + styles.activeItem : styles.menuItem}
                  onClick={() => handleActivation('projects')}>
                <div className={styles.itemText}>
                  <MdMovie className={styles.iconsColor} />&#160;&#160;Projects
                </div>
              </li>
              <li className={activation.upgrade ? 
                  styles.menuItem + ' ' + styles.activeItem : styles.menuItem}
                  onClick={() => handleActivation('upgrade')}>
                <div className={styles.itemText}>
                  <FontAwesomeIcon icon={faCrown} 
                                    className={styles.iconsColor}
                                    size='sm' />&#160;&#160;Upgrade
                </div>
              </li>
              <li className={activation.credit ? 
                  styles.menuItem + ' ' + styles.activeItem : styles.menuItem}
                  onClick={() => handleActivation('credit')}>
                <div className={styles.itemText}>
                  <HiCurrencyDollar className={styles.iconsColor} />&#160;&#160;Credit & Billing
                </div>
              </li>
              <li className={activation.settings ? 
                  styles.menuItem + ' ' + styles.activeItem : styles.menuItem}
                  onClick={() => handleActivation('settings')}>
                <div className={styles.itemText}>
                  <MdSettings className={styles.iconsColor} />&#160;&#160;Settings
                </div>
              </li>
            </ul>
            <div className={styles.logoutContainer}>
              <button className={styles.menuItem + ' ' + styles.logoutButton}
                      onClick={logout}>
                <LuLogOut />&#160;&#160;Logout</button>
            </div>
          </div>
        </div>
        <div className={'col-xl-10 col-lg-9 col-md-8 col-sm-7 col-6 ' + styles.mainColumn}>
          <div className={styles.contentArea}>
            <div className='row'>
              <p className={styles.titleText}>Recent Projects</p>
              <hr />
            </div>
            <div className={'row ' + styles.projectRow}>
              <div className={styles.scrollPrev}>
                <button className={styles.scrollButton} 
                        onClick={() => scroll(-200)}>
                  <IoIosArrowBack />
                </button>
              </div>
              <div className={styles.projectContainer} ref={videoRef}>
                {videoThumbnails.map((item) => {
                  return(
                    <div className={styles.projectWrapper} key={item.file_id}>
                      <img src={item.thumbnail_dl_url} alt='Video Thumbnail'
                          className={styles.videoThumb} 
                          onClick={() => navigate(`/${item.file_id}`)}/>
                    </div>)
                })}
              </div>
              <div className={styles.scrollNext}>
                <button className={styles.scrollButton} 
                        onClick={() => scroll(200)}>
                  <IoIosArrowForward />
                </button>
              </div>
            </div>
            <div className='row'>
              <p className={styles.titleText}>Account Status</p>
              <hr />
            </div>
            <div className={'row ' + styles.videoRow}><div className={styles.middleDiv}></div></div>
            <div className='row'>
              <p className={styles.titleText}>Tutorials</p>
              <hr />
            </div>
            <div className={'row ' + styles.videoRow}>
              <div className='col-4'><div className={styles.bottomDiv}></div></div>
              <div className='col-4'><div className={styles.bottomDiv}></div></div>
              <div className='col-4'><div className={styles.bottomDiv}></div></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard
