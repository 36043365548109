import React, { useContext, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios';
import styles from './Login.module.css'
import AppDataContext from '../../../contexts/AppDataContext';
import logoImage from '../../../data/images/FilmEditor-white.png'

function Login() {
  const [displayCondition, setDisplayCondition] = useState(false)
  const { setIsAuthenticated, baseURL } = useContext(AppDataContext)
  const navigate = useNavigate()
  const loginURL = `${baseURL}/adminstrator/login/`
  const setCsrfURL = `${baseURL}/adminstrator/setcsrf/`

  const handleSubmit = (event) => {
	event.preventDefault();
	  axios.post(loginURL,
							{'username': event.target.elements.username.value,
							'password': event.target.elements.password.value}, 
					{withCredentials: true})
					.then(() => {setIsAuthenticated(true)
								setDisplayCondition(true)
								navigate('/', {replace: true})})
					.catch(err => console.log('Login:', err));
	  axios.get(setCsrfURL)
				.then(res => console.log('Csrf:', res))
				.catch(err => console.log('Csrf:', err));        
  }
  const handleAlertDisplay = () => {
	  setDisplayCondition(false)
  }
	
  let alertClass = displayCondition ? styles.alertDisplay :
									  styles.alertDisplayNone

  return (
	<div className={styles.bodyContainer}>
	  <div className={alertClass}>
			<div className='row justify-content-between'>
				<div className='col-5'>
					<p>You are logged in successfully</p>
				</div>
				<div className={'col-1 ' + styles.dismissButtonContainer}>
					<button className={styles.dismissButton} onClick={handleAlertDisplay}>X</button>
				</div>
			</div>
	  </div>
		<div className='container-fluid'>
			<div className='row justify-content-center'>
				<div className={'card col-xl-4 col-lg-4 col-md-6 col-sm-8 col-10 ' + styles.cardContainer}>
					<div className={styles.cardBody}>
						<div className={'card-title ' + styles.logoContainer}>
							<img src={logoImage} alt="LOGO" className={styles.logo} />
						</div>
						<form onSubmit={(event) => handleSubmit(event)}>
							<div className={'form-group ' + styles.form}>
								<input className={'form-control ' + styles.formControl + ' ' + 
																styles.inputPlaceholder}
										id='formInput#text'
										type='text'
										name='username'
										placeholder='Username'/>
							</div>
							<div className={'form-group ' + styles.form}>
								<input className={'form-control ' + styles.formControl + ' ' + 
																styles.inputPlaceholder}
										id='formInput#password'
										type='password'
										name='password'
										placeholder='Password'/>
							</div>
							<div className={'row justify-content-between ' + styles.rememberForgetRow}>
								<div className={'col-5 ' + styles.rememberForgetContainer + ' ' +
																styles.checkboxDirection}>
									<label htmlFor="rememberMe" className={styles.robotLabel}>Remember me</label>
									<input type="checkbox" name="rememberMe"
													id="rememberMe" className={styles.checkboxDirection}/>
								</div>
								<div className={'col-5 ' + styles.rememberForgetContainer}>
									<a href='/'  className={styles.forgetLink}>Forget Password?</a>
								</div>
							</div>
							<div className="row">
								<div className={styles.RobotContainer + ' ' + styles.checkboxDirection}>
									<label htmlFor="notRobot" className={styles.robotLabel}>I'm not Robot</label>
									<input type="checkbox" name="notRobot"
													id="notRobot" className={styles.robotCheckbox}/>
								</div>
							</div>
							<div className={'row form-group ' + styles.buttonContainer}>
								<input className={'btn btn-primary ' + styles.button
													+ ' ' + styles.buttonColor} 
												type='submit' value='Log In' />
							</div>
						</form>
						<hr />
						<div className={styles.alternative}>
							<div className={'row ' + styles.question}>
								<p>Don’t have an Account?</p>
							</div>
							<div className={'row form-group ' + styles.buttonContainer}>
								<button className={'btn btn-warning ' + styles.button}
												onClick={() => navigate('/signup')}>
									Signup
								</button>
							</div>
						</div>
						<div className={styles.homepageLink}>
							<Link to='/'>Homepage</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
  )
}

export default Login
