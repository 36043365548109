import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AppDataProvider } from "./contexts/AppDataContext";

import Login from "./components/pages/Login";
import Signup from "./components/pages/Signup";
import Homepage from "./components/Homepage/Homepage";
import EditHomepage from "./components/EditHomepage/EditHomepage";
import Dashboard from "./components/pages/Dashboard/Dashboard";

function App() {
  return (
    <BrowserRouter>
      <AppDataProvider>
        <Routes>
          <Route exact path="/" element={<Homepage />}></Route>
          <Route path="/:file_id" element={<EditHomepage />}></Route>
          <Route path="/dashboard" element={<Dashboard />}></Route>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/signup" element={<Signup />}></Route>
        </Routes>
      </AppDataProvider>
    </BrowserRouter>
  );
}

export default App;
