import axios from "axios";
import {createContext, useEffect, useState} from "react";
import Cookies from 'js-cookie';

const AppDataContext = createContext()

export const AppDataProvider = ({children}) => {
  const baseURL = process.env.REACT_APP_MAIN_PAGE_URL
  const socketURL = process.env.REACT_APP_SOCKET_PATH
  const insertionEndpoint = '/transcript/transcript/insert/'
  const splitionEndpoint = '/transcript/transcript/split/'
  const updateEndpoint = '/transcript/transcript/'
  const refreshEndpoint = '/transcript/transcript'
  const [Subtitle, setSubtitle] = useState([])
  const [videoFileURL, setVideoFileURL] = useState(null)
  const [subtitleSrc, setSubtitleSrc] = useState(null)
  const [uploadParams, setUploadParams] = useState({})
  const [isAuth, setIsAuth] = useState(false)
  const [isLoading, setIsLoading] = useState({'condition': false, 'message': ''})
  const [panelItem, setPanelItem] = useState({'start_time': '', 'end_time': '', 'id': ''})

  useEffect(() => {
    if (Cookies.get('csrftoken')) {
        setIsAuth(true)
    }
  }, [])
  
  const handleUpload = (videoRef) => {
    if (videoRef.current.files.length > 0) {
        setVideoFileURL(URL.createObjectURL(videoRef.current.files[0]))
    }
  }

  const handleSubtitleTrack = () => {
    setSubtitleSrc(
        createSubtitleSrc(subtitleConverter(Subtitle)))
  }

  function dataProvider(transcriptData) {
    if (transcriptData.length === 0) {
      setSubtitle([])
    }else{
      const newItem = {...transcriptData, 'id': `${transcriptData.id}`}
      setSubtitle((Subtitle) => [...Subtitle, newItem])
    }
  }

  function refreshData(fileId) {
    axios.get(`${baseURL}${refreshEndpoint}?file_id=${fileId}`,
              {withCredentials: true})
        .then((res) => {
          setSubtitle([])// eslint-disable-next-line
          res.data.transcripts.map((item) => {dataProvider(item)})
      })
        .catch((err) => {console.log(err)})
  }

  function handleInsertion(item) {
    const itemIndex = Subtitle.findIndex(obj => obj.id === item.id)
    const localSubtitle = [...Subtitle]
    const startValue = item.end_time
    const endValue = ((itemIndex === localSubtitle.length - 1) ? 
          item.end_time : localSubtitle[itemIndex + 1].start_time)
    const requestItem = {
        'previous_id': Number(item.id),
        'start_time': startValue,
        'end_time': endValue,
        'original_transcript': '',
        'translated_transcript': '',
        'file_id': uploadParams.file_id,
        'lang': 'en',
    }
    
    axios.post(`${baseURL}${insertionEndpoint}`,
               requestItem, {withCredentials: true})
         .then((res) => {
            const newItem = {
                'id': `${res.data.id}`,
                'lang': requestItem.lang,
                'start_time': requestItem.start_time,
                'end_time': requestItem.end_time,
                'original_transcript': requestItem.original_transcript,
                'translated_transcript': requestItem.translated_transcript,
            }
            const firstPart = localSubtitle.splice(0, itemIndex + 1)
            const secondPart = [...localSubtitle]
            setSubtitle([...firstPart, newItem, ...secondPart])
         })
         .catch(err => console.log(err))
  }

  function handleSplition(item) {
    const itemIndex = Subtitle.findIndex(obj => obj.id === item.id)
    const localSubtitle = [...Subtitle]
    const middleValue = (
        `${item.end_time.substr(0, 6)}`+
        `${('0' + ((parseFloat(item.start_time.substr(6, 6))+
            parseFloat(item.end_time.substr(6, 6)))/2).toFixed(3)).slice(-6)}`
    )
    const splitedEngString = item.original_transcript.split(' ')
    const splitedFaString = item.translated_transcript.split(' ')
    
    const requestItems = [{
      'id': Number(item.id),
      'start_time': item.start_time,
      'end_time': ((middleValue === ':0NaN') ? '' : middleValue),
      'original_transcript': splitedEngString.splice(0, Math.floor(splitedEngString.length/2)).join(' '),
      'translated_transcript': splitedFaString.splice(0, Math.floor(splitedFaString.length/2)).join(' '),
    },{
      'start_time': ((middleValue === ':0NaN') ? '' : middleValue),
      'end_time': item.end_time,
      'original_transcript': splitedEngString.join(' '),
      'translated_transcript': splitedFaString.join(' '),
    }]
    
    axios.post(`${baseURL}${splitionEndpoint}`,
                {'items': requestItems},
                {withCredentials: true})
        .then((res) => {
          const newItem1 = {...requestItems[0], 'id': item.id}
          const newItem2 = {...requestItems[1], 'id':`${res.data.id}`}
          const firstPart = localSubtitle.splice(0, itemIndex)
          const secondPart = localSubtitle.splice(1, localSubtitle.length-1)
          setSubtitle([...firstPart, newItem1, newItem2, ...secondPart])})
        .catch(err => console.log(err))
    
  }

  function handleDeletion(item) {
    let deleteIndex = Subtitle.findIndex(obj => obj.id === item.id)
    axios.delete(`${baseURL}${updateEndpoint}`,
                 {withCredentials: true,
                  data: {'id': Number(item.id)}})
        .then(() =>{ 
          Subtitle.splice(deleteIndex, 1)
          setSubtitle([...Subtitle])
          setPanelItem({'start_time': '', 'end_time': '', 'id': ''})})
        .catch(err => console.log(err))
  }

  function handleMerge(item, side) {
    let mergeIndex = Subtitle.findIndex(obj => obj.id === item.id)
    if (side === 'back') {
      mergeIndex -= 1
    }
    let mergeItem = {
        'id': Number(Subtitle[mergeIndex].id),
        'start_time': Subtitle[mergeIndex].start_time,
        'end_time': Subtitle[mergeIndex + 1].end_time,
        'original_transcript': (Subtitle[mergeIndex].original_transcript + ' '
                              + Subtitle[mergeIndex + 1].original_transcript),
        'translated_transcript': (Subtitle[mergeIndex].translated_transcript + ' '
                                + Subtitle[mergeIndex + 1].translated_transcript),
        'lang': Subtitle[mergeIndex].lang,
        'message_id': Subtitle[mergeIndex].message_id,
        'task_id': Subtitle[mergeIndex].task_id
    }
    const stringId = `${mergeItem.id}`
    axios.delete(`${baseURL}${updateEndpoint}`,
                 {withCredentials: true,
                  data: {'id': Number(Subtitle[mergeIndex + 1].id)}})
        .then()
        .catch((err) => console.log(err))
    axios.patch(`${baseURL}${updateEndpoint}`,
                mergeItem, {withCredentials: true})
          .then(() => {
              const firstPart = Subtitle.splice(0, mergeIndex)
              const secondPart = Subtitle.splice(2, Subtitle.length - 2)
              mergeItem.id = stringId
              setSubtitle([...firstPart, mergeItem, ...secondPart])
              setPanelItem(mergeItem)
          })
          .catch((err) => console.log(err))
  }

  function subtitleConverter(subtitleBatch) {
    return subtitleBatch.reduce((acc, item) => (
        acc += (`${item.id}\n${item.start_time} --> ${item.end_time}\n${item.translated_transcript}\n\n`)
    ), 'WEBVTT\n\n')
  }

  function createSubtitleSrc(subtitleStringVttFormat) {
    const vttSubtitleFile = new File([subtitleStringVttFormat],
        {type: "text/plain"});
    return URL.createObjectURL(vttSubtitleFile)
  }

  function handleSubtitleChange(item) {
    let customSub = [...Subtitle]
    const objIndex = customSub.findIndex(obj => obj.id === item.id)
    customSub[objIndex] = item
    setSubtitle(customSub)
  }

  function setUploadParamValues(data) {
    setUploadParams(data)
  }

  function handleUpdate(id, property, propertyValue) {
    let submitData = ({
        'id': Number(id),
        [property]: propertyValue
    })
    axios.patch(`${baseURL}${updateEndpoint}`,
                submitData, {withCredentials: true})
         .then()
         .catch(err => console.log(err))
  }

  function isFormatted(value){
    if (value.length !== 12) {
      return false
    }else {
      if (value[2] !== ':') {
        return false
      }else {
        if (value[5] !== ':') {
          return false
        }else {
          if (value[8] !== '.') {
            return false
          }else {
            if (isNaN(Number(value.substr(0, 2)))) {
              return false
            }else {
              if (isNaN(Number(value.substr(3, 2)))) {
                return false
              }else {
                if (isNaN(Number(value.substr(6, 6)))) {
                  return false
                }else {
                  return true
                }
              }
            }
          }
        }
      }
    }
  }

  function isValidTime(currentTime, property, itemId) {
    const itemIndex = Subtitle.findIndex(obj => obj.id === itemId)
    const startTime = ((property === 'start_time') && (itemIndex !== 0) ? 
                        Subtitle[itemIndex-1].end_time : '00:00:00.000')
    const endTime = ((property === 'end_time') && (itemIndex !== (Subtitle.length-1)) ? 
                      Subtitle[itemIndex+1].start_time : '99:59:59.999')
    if ((Number(currentTime.substr(0, 2)) < Number(startTime.substr(0, 2))) 
     || (Number(currentTime.substr(0, 2)) > Number(endTime.substr(0, 2)))) {
      return false
    } else if ((Number(currentTime.substr(0, 2)) > Number(startTime.substr(0, 2))) 
            && (Number(currentTime.substr(0, 2)) < Number(endTime.substr(0, 2)))) {
      return true
    } else {
      if ((Number(currentTime.substr(3, 2)) < Number(startTime.substr(3, 2))) 
          || (Number(currentTime.substr(3, 2)) > Number(endTime.substr(3, 2)))) {
        return false
      }else if ((Number(currentTime.substr(3, 2)) > Number(startTime.substr(3, 2))) 
                 && (Number(currentTime.substr(3, 2)) < Number(endTime.substr(3, 2)))) {
        	return true
      }else {
        if (Number(currentTime.substr(6, 6)) < Number(startTime.substr(6, 6))) {
          return false
        } else {
          if (Number(currentTime.substr(6, 6)) > Number(endTime.substr(6, 6))) {
            return false
          }else { return true}
        }
      }
    }
  }

  function retreiveIndex(item) {
    return Subtitle.indexOf(item)
  }

  function retreiveLength() {
    return Subtitle.length
  }

  function setIsAuthenticated(authValue) {
    setIsAuth(authValue)
  }

  function setIsLoadingValue(loadValue) {
    setIsLoading(loadValue)
  }

  function setPanelItemValue(panelItemValue) {
    setPanelItem(panelItemValue)
  }

  return (
    <AppDataContext.Provider
      value={{
        baseURL,
        socketURL,
        Subtitle,
        retreiveIndex,
        retreiveLength,
        dataProvider,
        refreshData,
        handleUpload,
        handleInsertion,
        handleSplition,
        handleDeletion,
        handleUpdate,
        handleMerge,
        handleSubtitleTrack,
        handleSubtitleChange,
        videoFileURL,
        subtitleSrc,
        uploadParams,
        setUploadParamValues,
        isFormatted,
        isValidTime,
        isAuth,
        setIsAuthenticated,
        isLoading,
        setIsLoadingValue,
        panelItem,
        setPanelItemValue,
      }}>
      {children}
    </AppDataContext.Provider>
  )
}


export default AppDataContext