import React, { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ReactLoading from 'react-loading'
import axios from "axios"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import styles from './Homepage.module.css'
import AppDataContext from '../../contexts/AppDataContext'
import logoImage from '../../data/images/FilmEditor-white.png'

axios.defaults.withCredentials = true;
axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'x-csrftoken'

function Homepage() {
  const videoRef = useRef(null)
  const isUploadedToMinio = useRef(false)
  const [isLoading, setIsLoading] = useState(false)
  const [fileInputLabel, setFileInputLabel] = useState('Upload your video here')
  const [uploadDisable, setUploadDisable] = useState(true)
  const navigate = useNavigate()
  const {handleUpload, handleSubtitleTrack, baseURL,
         setIsLoadingValue, isAuth, uploadParams,
         setUploadParamValues} = useContext(AppDataContext)

  const uploadFileEndpoint = '/transcript/upload_file/'

  useEffect(() => {
    if (uploadParams.url) {      
      axios.put(uploadParams.url, videoRef.current.files[0],
              {redirect: "follow", headers: {'Content-Type': ''}})
          .then(() => {isUploadedToMinio.current = true
                          setIsLoading(false)
                          navigate(`/${uploadParams.file_id}`,
                            {state: {'isUploadedToMinio': isUploadedToMinio}})})
          .catch(err => console.log('Put to MinIo:', err))
        }// eslint-disable-next-line
  }, [uploadParams])

  const handleFileInputChange = (event) => {
    setUploadDisable(false)
    setFileInputLabel(event.target.value.split('\\')[2])
  }
  
  const inputFileIsValid = (file) => {
    if (file.current.files[0].type === 'video/mp4'){
      return true} else {return false}
  }

  const handleuploadClick = () => {
    setIsLoadingValue({'condition': true, 
      'message': 'Uploading your video file ... '})
    if (inputFileIsValid(videoRef)) {
      handleSubtitleTrack()    
      axios.post(`${baseURL}${uploadFileEndpoint}`,
                {'file_name': videoRef.current.value.split('\\')[2]},
                {withCredentials: true})
          .then((res) => {setUploadParamValues(res.data)
                          setIsLoading(true)})
          .catch((err) => console.log('Post Filename', err));

    } else {setFileInputLabel('**You have to upload a "video.mp4" file **')}
  }

  return (
    <div className={styles.mainContainer}>
      <div className={'row justify-content-between ' + styles.topRow}>
        <div className='col-xl-3 col-lg-3 col-md-4 col-sm-4 col-4'>
          <div className={styles.logoContainer}>
            <img src={logoImage} alt="LOGO" className={styles.logo} />
          </div>
        </div>
        <div className={'col-xl-2 col-lg-3 col-md-3 col-sm-5 col-5 ' + styles.authButtons}>
          <div className={'row ' + styles.buttonRow}>
            <div className={'col-5 ' + styles.buttonContainer
                            + ' ' + (isAuth && styles.displayNone)}>
              <button className={styles.loginButton}
                      onClick={() => navigate('/login')}>
                Login
              </button>
            </div>
            <div className={'col-5 ' + styles.buttonContainer
                            + ' ' + (isAuth && styles.displayNone)}>
              <button className={styles.signupButton}
                      onClick={() => navigate('/signup')}>
                Signup
              </button>
            </div>
          </div>
        </div>
        <div className={'col-1 ' + (!isAuth && styles.displayNone)}>
          <div className={styles.iconColumn}>
            <div className={styles.headerIcon}
                onClick={() => navigate('/dashboard')}>
              <FontAwesomeIcon icon={faUser} className={styles.userIcon} />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.innerContainer}>
        <div className={'row justify-content-center ' + styles.mainRow}>
          <div className='col-xl-8 col-lg-8 col-md-9 col-sm-10 col-11'>
            <div className={isLoading ? styles.loadingContainer: styles.displayNone}>
              <ReactLoading type="spin" color="#BCF2F6"
                          height={100} width={100} />
            </div>
            <form className={styles.form} 
                  onSubmit={(event) => {
                  event.preventDefault();
                  handleUpload(videoRef)}}
                  encType='multipart/form-data'>
              <div className={'row justify-content-center ' + styles.formRowContainer}>
                <div className={'col-sm-8 col-7 ' + styles.inputFileContainer}>
                  <label htmlFor='uploadVideo' className={styles.inputLabel}>
                    {fileInputLabel}
                  </label>
                  <input className={styles.inputFile}
                          type='file' name='uploadVideo'
                          ref={videoRef}
                          accept='.mp4'
                          onChange={(event) => handleFileInputChange(event)}
                          id='uploadVideo' />
                </div>
                <div className={'col-xl-1 col-lg-1 col-md-2 col-sm-3 col-3 ' + styles.uploadButtonContainer}>
                  <input className={styles.uploadButton}
                          disabled={!isAuth || uploadDisable}
                          type='submit' value='Upload' 
                          onClick={handleuploadClick}/>
                </div>
              </div>
            </form>
          </div>
          <div className='row justify-content-center'>
            <div className='col-xl-7 col-lg-7 col-md-9 col-sm-10 col-11'>
              <p className={styles.texts}>Edit Your Videos With AI</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Homepage
