import { useContext } from 'react'
import styles from './EditSubtitle.module.css'
import SubtitleList from '../SubtitleList'
import LoadingStatus from '../LoadingStatus'
import AppDataContext from '../../contexts/AppDataContext'

function EditSubtitle() {
    const { isLoading } = useContext(AppDataContext)
    
    return (
    <div className='editSubtitleContainerInner'>
        {isLoading.condition ? 
        <div className={styles.loadingContainer}>
            <LoadingStatus loadingMessage={isLoading.message} />
        </div>
        : <SubtitleList />}
    </div>
)
}

export default EditSubtitle
