import React, { useContext, useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import { io } from 'socket.io-client'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import styles from './EditHomepage.module.css'

import EditSubtitle from '../EditSubtitle/EditSubtitle'
import DisplayVideo from  '../DisplayVideo/DisplayVideo'
import EditPanel from '../EditPanel'
import VideoTimeline from '../VideoTimeline/VideoTimeline'
import ExportData from '../ExportData/ExportData'
import AppDataContext from '../../contexts/AppDataContext'
import logoImage from '../../data/images/FilmEditor-main.png'

function EditHomepage() {
  const {state} = useLocation()
  const { file_id } = useParams()
  const navigate = useNavigate()
  const videoRef = useRef(null)
  const [notUploaded, setNotUploaded] = useState(true)
  const [keepOpen, setKeepOpen] = useState(true)
  const [uploadDisable, setUploadDisable] = useState(true)
  const [fileInputLabel, setFileInputLabel] = useState(
    'You need to upload your video again! Click here to select')
  const { uploadParams, socketURL, dataProvider,
          setIsLoadingValue, baseURL, refreshData, 
          handleUpload} = useContext(AppDataContext)
  const uploadFileEndpoint = '/transcript/upload_file/'

  useEffect(() => {
    if (state) {
      const {isUploadedToMinio} = state
      axios.patch(`${baseURL}${uploadFileEndpoint}`,
        {'file_id': uploadParams.file_id, 'task_id': uploadParams.task_id},
        {withCredentials: true})
          .then(() => {
                setIsLoadingValue({'condition': true,
                                   'message': 'Loading the Subtitle editing rows ... '})
                dataProvider([])
          })
          .catch(err => console.log('Patch file_id:', err))
      isUploadedToMinio.current = false
    } else {
      uploadParams.file_id = file_id
      refreshData(file_id)// eslint-disable-next-line
    }}, [])

  useEffect(() => {
    const socket = io(socketURL)

    socket.on('connect', () => {
      console.log('Connected to server')
    })

    socket.on('disconnect', () => {
      console.log('Disconnected from server');
    })
    if (uploadParams.task_id) {
      socket.emit('subscribe', uploadParams.task_id);
    }
    
    socket.on('message', (data, callback) => {
      if (data.message_id) {
        if (!data.keep_open) {setKeepOpen(false)}
        dataProvider(data);
        setIsLoadingValue({'condition': false,
                          'message': ''});
      }
      if (callback) {
          callback({status: 'received',
                    message_id: data.message_id,
                    task_id: data.task_id});
      }
    })

    if (!keepOpen) {
      socket.disconnect()
    }
    return () => {
      socket.disconnect()
    };// eslint-disable-next-line
  },[])
  
  const handleFileInputChange = (event) => {
    setUploadDisable(false)
    setFileInputLabel(event.target.value.split('\\')[2])
  }

  return (
    <div className={'container-fluid ' + styles.mainContainer}>
      <div className={'row justify-content-between ' + styles.headerSection}>
        <div className='col-3'>
          <div className={styles.logoContainer}>
            <img src={logoImage} alt="LOGO"
                className={styles.logo} />
          </div>
        </div>
        <div className='col-1'>
          <div className={styles.iconColumn}>
            <div className={styles.headerIcon}
                onClick={() => navigate('/dashboard')}>
              <FontAwesomeIcon icon={faUser} className={styles.userIcon} />
            </div>
          </div>
        </div>
      </div>
      <div className={'row ' + styles.mainRow}>
        <div className={'col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12 '
                         + styles.editArea}>
          <div className={styles.editSubtitleContainer}>
            <EditSubtitle />
          </div>
          <div className={'row ' + styles.editPanel}>
            <EditPanel />
          </div>
        </div>
        <div className='col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12'>
					<div className={styles.displayVideoContainer}>
            <div className='row'>
              {(!state && notUploaded) ? (<div className={styles.upload}>
                <form className={styles.form} 
                    onSubmit={(event) => {
                    event.preventDefault();                    
                    handleUpload(videoRef)
                    setNotUploaded(false)}}
                    encType='multipart/form-data'>
                  <div>
                    <div className={styles.inputContainer}>
                      <label className={styles.inputLabel}
                              htmlFor='uploadVideo'>
                        {fileInputLabel}
                      </label>
                      <input className={styles.inputFile}
                              type='file' name='uploadVideo'
                              ref={videoRef}
                              accept='.mp4'
                              onChange={(event) => handleFileInputChange(event)}
                              id='uploadVideo' />
                    </div>
                    <div>
                      <input className={styles.uploadButton}
                            disabled={uploadDisable}
                            type='submit' value='Upload'/>
                    </div>
                  </div>
                </form>
              </div>) : <DisplayVideo />}
            </div>
            <div className={uploadParams.file_id ? ('row ' + styles.exportContainer) :
                           styles.displayNone}><ExportData /></div>
          </div>
          <div className={styles.refreshButtonContainer}>
            <button className={styles.refresh}
                    onClick={() => refreshData(uploadParams.file_id)}>
              Refresh
            </button>
          </div>
        </div>
      </div>
      <div className={'row ' + styles.timelineRow}>
        <VideoTimeline />
      </div>
    </div>
  )
}

export default EditHomepage
