import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { MdMergeType } from "react-icons/md";
import styles from './MergeButton.module.css'
import AppDataContext from '../../contexts/AppDataContext';

function MergeButton({ buttonBgColor, item, side, isFirst, isLast }) {
	const { handleMerge } = useContext(AppDataContext)
  return (
    <div>
      <button className={styles.button}
							style={{backgroundColor: buttonBgColor}}
              disabled={((side === 'back') && isFirst)
                    || ((side === 'front') && isLast)}
							onClick={() => handleMerge(item, side)}>
				Merge&#160;
        <MdMergeType className={styles.mergeIcon} />
      </button>
    </div>
  )
}

MergeButton.propTypes = {
	buttonBgColor: PropTypes.string,
  item: PropTypes.shape({
    id: PropTypes.string || null,
    start_time: PropTypes.string || null,
    end_time: PropTypes.string || null,
    original_transcript: PropTypes.string || null,
    translated_transcript: PropTypes.string || null,
  }),
  side: PropTypes.string,
}

export default MergeButton
